<template>
  <div id="admin_index">
    <el-collapse v-model="activeNames">
      <el-collapse-item name="0">
        <template slot="title">
          平台设置<i class="el-icon-s-tools"></i>
        </template>
        <div style="text-align: left" class="setItem">
          <el-button size="small" @click="$router.push({path: '/admin/system/set'})">系统设置</el-button>
          <el-button size="small" @click="$router.push({path: '/admin/videoSearch/set'})">资源搜索设置</el-button>
          <el-button size="small" @click="$router.push({path: '/admin/insideTv'})">影视资源添加</el-button>
          <el-button size="small" @click="$router.push({path: '/admin/banner'})">Banner</el-button>
          <el-button size="small" @click="$router.push({path: '/admin/externalApp'})">创意程序</el-button>
          <el-button size="small" @click="$router.push({path: '/admin/macCms'})">macCms</el-button>
        </div>
      </el-collapse-item>
      <el-collapse-item name="1">
        <template slot="title">
          ChatGPT个性设置<i class="header-icon el-icon-info"></i>
        </template>
        <el-input placeholder="请输入风格内容" v-model="gptSettings.gptStyleDesc" class="input-with-select">
          <el-button slot="append" icon="el-icon-refresh" @click="getGptStyle"></el-button>
          <el-button slot="append" icon="el-icon-check" @click="setGptStyle"></el-button>
        </el-input>
        <el-input placeholder="请输入模型" v-model="gptSettings.mode" class="input-with-select">
          <el-button slot="append" icon="el-icon-refresh" @click="getGptStyle"></el-button>
          <el-button slot="append" icon="el-icon-check" @click="setGptStyle"></el-button>
        </el-input>
      </el-collapse-item>
      <el-collapse-item name="2">
        <template slot="title">
          ChatGPT聊天记录<i class="el-icon-s-order"></i>
        </template>
        <el-row type="flex" class="row-bg" justify="end">
          <el-col :span="8">
            <el-button @click="chatGPTDialogVisible = true">列表显示</el-button>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>

    <el-dialog
        title="chatGPT列表显示"
        :visible.sync="chatGPTDialogVisible"
        width="90%"
        :before-close="handleClose">
      <ul>
        <li v-for="(item, index) in gptCacheUsers" :key="index">
          <el-radio v-model="chatGPTUserId" :label="index">ID:
            {{ item.usrId }}<span>&nbsp;用户名: {{ item.usrName }}</span></el-radio>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="cacheSave">存储</el-button>
    <el-button type="primary" @click="cacheUsers">显示</el-button>
    <el-button type="primary" @click="gptMessageJump">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import GptChatAsk from "../../request/expand/GptChat";

export default {
  name: "admin_index",
  data() {
    return {
      gptChatAsk: new GptChatAsk(),
      activeNames: '0',
      chatGptIndividuality: null,
      gptSettings: {
        "gptStyleDesc": "",
        "mode": ""
      },
      chatGPTDialogVisible: false,
      chatGPTUserId: 0,
      gptCacheUsers: null
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    handleChange(val) {
      console.log(val);
    },
    /**
     * 获取chatGPT个性风格
     */
    getGptStyle() {
      this.gptChatAsk.getGptSettings().then(res => {
        console.log(res == null)
        this.gptSettings = res;
      })
    },
    /**
     * 设置chatGPT个性风格
     */
    setGptStyle() {
      this.gptChatAsk.setGptSettings(this.gptSettings).then(res => {
        if (res.code == 'success') {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'success'
          });
        }
      })
    },
    /**
     * 获取chatGPT聊天用户信息
     */
    cacheUsers() {
      this.gptChatAsk.cacheUsers().then(res => {
        this.gptCacheUsers = res;
      })
    },
    /**
     * chatGPT消息存储
     */
    cacheSave() {
      const user = this.gptCacheUsers[this.chatGPTUserId]
    },
    /**
     * gpt聊天跳转
     */
    gptMessageJump() {
      const user = this.gptCacheUsers[this.chatGPTUserId]
      this.$router.push({
        name: 'chatGPTMsg',
        query: {
          usrId: user.usrId,
          usrName: user.usrName
        }
      })
    }
  }
}
</script>

<style scoped>
ul {
  padding: 0;
  text-align: left;
  height: 400px;
  overflow: auto
}

li {
  list-style: none;
  padding: 5px 0;
  border-bottom: 1px solid;
}
.setItem > .el-button {
  margin: 0;
  padding: 10px;
  margin-left: 5px;
}
</style>